import React, { useCallback, useState } from 'react';
import memoize from "memoize-one";
import CycleDemandListCreator from "views/planning/CycleDemandListCreator";
import Cycle, { CYCLE_STATUS } from "components/ClassWrapper/Cycle";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import { addAllToMap, parseISODateToMilliseconds, printIsoTime, getISODateFromISODateTime, type Dict, logErrorGroup, getFullNameWithPrefixDoctor, printIsoDate, getFullNameWithPrefix, getLocalDateStrFromISO } from "Utils";
import { UncontrolledTooltip, ButtonGroup } from "reactstrap"
import Button from "reactstrap/es/Button";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import TimelineWrapper from "views/planning/TimelineWrapper";
import moment, { Moment } from 'moment';
import CyclePlanningRequest from "components/LogicClass/CyclePlanningRequest";
import Rendezvous from "components/ClassWrapper/Rendezvous";
import CycleWrapper from "components/ClassWrapper/CycleWrapper";
import UController from "components/Controller/UController";
import InfoModal from "components/utility/InfoModal";
import { fetchPlaceListWithDict } from "components/Controller/ReusableBulkCall";
import type { ModelId } from "components/ClassWrapper/BasicModel";
import { BottomContainer, Container, MainContainer, SubBottomContainer, TopContainer } from 'assets/styled-components/CalendarContainer';
import styled from 'styled-components';
import AvailabilitiesAPI, { AbsenceExceptional } from 'api/AvailabilitiesAPI';
import { DateTimeLocalFormat, env } from 'Constants';
import { OptaEnumDict } from 'Utils';
import Material from "components/ClassWrapper/Material";
import type { SeatType } from 'components/ClassWrapper/SeatType';
import { BED, CHAIR } from 'components/ClassWrapper/SeatType';
import { SectorFilter } from 'components/picker/SectorFilter';
import RendezvousForm from 'components/RendezvousForm';
import CycleSelectorViewer from 'views/planning/CycleSelectorViewer';
import { Prompt } from 'react-router';
import { generatePlanningRdvList } from 'tools/pdf_generators';
import DateRangeSelector from 'components/picker/DateRangeSelector';
import ULiveFeed, { LiveSubscription } from 'components/Controller/ULiveFeed';
import { PlanningActionConfig } from "views/planning/PlanningActionConfig";
import { RdvMoveError, checkAndShiftDateTimeRdvs } from './RdvMovingHandler';
import { PlanningExplanation } from 'components/ClassWrapper/PlanningProposal';
import { withToastManager } from 'react-toast-notifications';
import type { SeatGroup } from 'components/ClassWrapper/SeatGroup';
import EntityPicker from 'components/picker/EntityPicker';
import Patient from 'components/ClassWrapper/Patient';
import { DoctorFilter } from 'components/picker/DoctorFilter';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';

type Place = {
    id: Number,
    title: String,
    stackItem: Boolean,
    backgroundColor: String,
    rawData: Material
}
type SeatTypeDescription = {
    type: SeatType,
    iconFa: String,
    translation: string,
}
const SELECTED_INTERVAL_DEFAULT_DURATION = moment.duration(env.DISPLAY_OPTIONS?.PLANNING?.SELECTED_INTERVAL_DEFAULT_DURATION ?? "P2W");

export const PopupContainer = styled('div')`
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1020;
    width: 80%;
    min-width: 800px;
    height: calc(100vh - 110px);
    background: #fff;
    border-left: 1px solid #C6C6C6;
    overflow-y: auto;
    overflow-x: hidden;
    display: ${props => props.view ? "block" : "none"};
`
export type RDV_DISPLAY_MODE = "BY_SECTOR" | "BY_DOCTOR";
class Planning extends React.PureComponent {

    state = {
        /**
         * @type {import('components/ClassWrapper/TimeClasses').DateString}
         */
        currentDate: getISODateFromISODateTime(new Date().toISOString()),
        lock: false,
        /**
         * @type {{content: string | ReactElement, level?: "info" | "warning" | "success", title?: string, html?: boolean}?}
         */
        msg: null,
        msgTimestamp: 0,
        /**
         * @type {Number | null}
         */
        editingRdvId: null,
        /**
         * @type {Dict<Cycle>}
         */
        cycleMap: {},
        /**
         * @type {Dict<Cycle>} backup of cycleMap
         */
        _cycleMap: {},
        /**
         * @type {OptaConfig}
         */
        config: {},
        /**
         * @type {Place[]}
         */
        placeList: [],
        /**
         * @type {Dict<Place>}
         */
        placeMap: {},
        /**
         * @type {SeatGroup[]}
         */
        seatWings: [],
        /**
         * @type {SeatGroup[]}
         */
        selectedSeatWings: [],
        /**
         * @type {{start: number, end: number}}
         */
        selectedInterval: {
            start: parseInt(moment().startOf("week").format("x")),
            end: parseInt(moment().startOf("week").add(SELECTED_INTERVAL_DEFAULT_DURATION).format("x")),
        },
        /**
         * @type {number[]}
         */
        selectedCycleIds: [],
        /**
         * @type {number[]}
         */
        selectedRdvIds: [],
        dict: {
            /**
             * @type {Dict<Sector>}
             */
            sector: {},
            /**
             * @type {Dict<MaterialType>}
             */
            materialType: {},
        },
        /**
         * @type {ModelId[]}
         */
        selectedSectorIds: [],
        /**
         * @type {Number[]}
         */
        selectedSectorIdsForRdvs: [],
        /**
         * @type {SeatType[]}
         */
        selectedSeatTypes: [],
        /**
         * @type {Boolean}
         */
        isLeftSidebarOpen: false,
        /**
         * @type {Number[]}
         */
        selectedDoctorIds: [],
        /**
        * @type {Boolean}
        */
        isPopupAddPatientContainerIsOpen: false,
        /**
        * @type {{start: number, end: number}[]}
        */
        publicHolidays: [],
        /**
         * @type {PlanningActionConfig}
         */
        planningActionConfig: {
            limitRdvInOpeningHour: true,
            moveFutureRdvSameCycle: true,
            noRdvOnWeekendsAndHolidays: true,
        },
        /**
         * @type {RDV_DISPLAY_MODE}
         */
        rdvDisplayMode: env?.DISPLAY_OPTIONS?.PLANNING?.RDV_COLOR ?? "BY_SECTOR"

    };

    _counter = 1;

    pickerRef = React.createRef(null);

    /**
     * @type {LiveSubscription | null}
     */
    rdvLiveFeedSubscription: LiveSubscription = null;

    /**
     * @type {LiveSubscription | null}
     */
    cycleBatchLiveFeedSubscription: LiveSubscription = null;

    mountLiveFeedSubscription = () => {
        this.rdvLiveFeedSubscription = ULiveFeed.todayRdvs.subscribe((latestRdvChange) => {
            const cycleId = latestRdvChange.cycleId;
            const rdvId = latestRdvChange.id;
            if (!this.state._cycleMap[cycleId]) {
                console.debug("RDV of id " + rdvId + " arrived but no cycle of id " + cycleId + " found");
                return; // Cycle update not arrived yet
            }
            const previousRdvState = this.state._cycleMap[cycleId].rendezvousList.find(v => v.id === rdvId);
            // Normally, Cycle does not add new Rdv. So we should find something
            if (!previousRdvState) {
                console.debug("RDV of id " + rdvId + " received but cycle of id " + cycleId + " does not have any RDV of this id");
                return;
            } else {
                console.debug("RDV of id " + rdvId + " received and cycle of id " + cycleId + " has this RDV");
                this.setState({
                    _cycleMap: {
                        ...this.state._cycleMap,
                        [cycleId]: {
                            ...this.state._cycleMap[cycleId],
                            rendezvousList: this.state._cycleMap[cycleId].rendezvousList.map(r => r.id !== rdvId || r.lastModifiedDateTime >= latestRdvChange.rdv.lastModifiedDateTime ? r : ({
                                ...r,
                                ...latestRdvChange.rdv,
                                registerStatus: CYCLE_STATUS.CONFIRMED,
                            }))
                        }
                    },
                    cycleMap: {
                        ...this.state.cycleMap,
                        [cycleId]: {
                            ...this.state.cycleMap[cycleId],
                            rendezvousList: this.state._cycleMap[cycleId].rendezvousList.map(r => r.id !== rdvId || r.lastModifiedDateTime >= latestRdvChange.rdv.lastModifiedDateTime ? r : ({
                                ...r,
                                ...latestRdvChange.rdv,
                                registerStatus: CYCLE_STATUS.CONFIRMED,
                            }))
                        }
                    }
                });
            }
        });
        this.cycleBatchLiveFeedSubscription = ULiveFeed.cycleBatchUpdates.subscribe((latestCycleBatchUpdate) => {
            const cycles = latestCycleBatchUpdate.cycles;
            const startDate = getISODateFromISODateTime(moment(this.state.selectedInterval.start).toISOString(true)),
                endDate = getISODateFromISODateTime(moment(this.state.selectedInterval.end).toISOString(true));
            // Only interested in cycles within selected intervals and newer
            const filteredCycles = cycles.filter(c => c.interval.start <= endDate && c.interval.end >= startDate
                && (
                    !this.state._cycleMap[c.id]
                    || this.state._cycleMap[c.id].lastModifiedDateTime < c.lastModifiedDateTime
                ));
            if (filteredCycles.length === 0) return;
            // Merge into backup and draft map
            const nextBackupCycleMap = { ...this.state._cycleMap };
            const nextDraftCycleMap = { ...this.state.cycleMap };
            filteredCycles.forEach(c => {
                const cycleId = c.id;
                if (!c.enabled) {
                    delete nextBackupCycleMap[cycleId];
                    delete nextDraftCycleMap[cycleId];
                } else {
                    // Simply replace with newer source
                    nextBackupCycleMap[cycleId] = c;
                    nextDraftCycleMap[cycleId] = new Cycle({
                        ...c,
                        registerStatus: CYCLE_STATUS.CONFIRMED,
                        rendezvousList: c.rendezvousList.map(r => new Rendezvous({ ...r, registerStatus: CYCLE_STATUS.CONFIRMED, }))
                    });
                }
            });
            const popupMessageCandidates: { cycle: Cycle, status: "DELETED" | "UPDATED" | "CREATED" }[] =
                filteredCycles.map(cycle => ({
                    cycle,
                    status: !cycle.enabled ? "DELETED" : !this.state._cycleMap[cycle.id] ? "CREATED" : "UPDATED",
                }));
            this.setState({
                cycleMap: nextDraftCycleMap,
                _cycleMap: nextBackupCycleMap,
            }, () => {
                popupMessageCandidates.forEach(msg => {
                    const patientNameId = getFullNameWithPrefix(msg.cycle.patient) + (!msg.cycle.patient.externalId?.value ? "" : ` (${msg.cycle.patient.externalId.value})`);
                    switch (msg.status) {
                        case 'DELETED':
                            this.props.toastManager.add(
                                <span>Le cycle du patient {patientNameId} a été annulé</span>,
                                {
                                    appearance: "warning",
                                }
                            )
                            break;
                        case 'UPDATED':
                            this.props.toastManager.add(
                                <span>Le cycle du patient {patientNameId} a été modifié</span>,
                                {
                                    appearance: "info",
                                }
                            )
                            break;
                        case 'CREATED':
                            this.props.toastManager.add(
                                <span>Le cycle du patient {patientNameId} a été créé</span>,
                                {
                                    appearance: "success",
                                }
                            )
                            break;
                        default:
                            break;
                    }
                })
            });
        });
    }

    unmountLiveFeedSubscription = () => {
        this.rdvLiveFeedSubscription?.unsubscribe();
        this.cycleBatchLiveFeedSubscription?.unsubscribe();
    }

    /**
     * @type {Object<SeatType, SeatTypeDescription>}
     */
    SUPPORTED_SEAT_TYPES = {
        BED: {
            type: BED,
            iconFa: "bed",
            translation: "Lit",
        },
        CHAIR: {
            type: CHAIR,
            iconFa: "chair",
            translation: "Fauteuil",
        },
    }

    componentDidMount = () =>
        this.setState({ lock: true, msg: { content: "Chargement de données..." } }, () =>
            Promise.all([
                this.loadEnumData(),
                this.loadConfig(),
                this.loadSeatGroupWings()
            ])
                .finally((msgs: string[] = []) =>
                    this.setState({
                        lock: false,
                        msg: { content: msgs.filter(msg => !!msg).join(" ") }
                    }))
                .then(() => this.mountLiveFeedSubscription()));

    componentWillUnmount = () => {
        this.unmountLiveFeedSubscription();
    }

    /**
     * Load sectors, material types
     * @return {Promise<void | string>} returns message when failing
     */
    loadEnumData = (): Promise<void | string> =>
        fetchPlaceListWithDict()
            .then(({ placeList, dict }: { placeList: Place[], dict: OptaEnumDict }) => {
                placeList.sort((p1, p2) => p1.rawData.number < p2.rawData.number ? -1 : p1.rawData.number === p2.rawData.number ? 0 : 1);
                const placeMap = placeList.reduce((m, p) => ({ ...m, [p.rawData.id]: p }), {});
                this.setState({
                    placeList,
                    placeMap,
                    dict,
                    selectedSectorIds: Object.values(dict.sector).map(sector => sector.id),
                    selectedSectorIdsForRdvs: Object.values(dict.sector).map(sector => sector.id),
                    selectedSeatTypes: Object.values(this.SUPPORTED_SEAT_TYPES).map(desc => desc.type),
                });
            })
            .catch(error => {
                logErrorGroup(error, "planning.load_seats");
                return "Echec de chargement de matériels.";
            });

    /**
     *
     * @return {Promise<void | string>}
     */
    loadConfig = () =>
        UController.planning.getConfig()
            .then(config => this.setState({ config }))
            .catch(error => {
                logErrorGroup(error, "planning.load_config");
                return "Echec de chargement de configuration.";
            });

    loadSeatGroupWings = (): Promise<void> =>
        UController.seatGroup.get({
            pageSize: 1000,
            search: "enabled==true;type==WING"
        })
            .then(res => this.setState({
                seatWings: [...res.array],
                selectedSeatWings: [],
            }))
            .catch(error => {
                logErrorGroup(error, "planning.load_wing_seat_groups");
                return "Echec de chargement des ailes";
            });

    /**
     *
     * @return {Promise<void>}
     */
    fetchCyclesAndGlobalAbsences = (): Promise<void> => {
        let start = moment(this.state.selectedInterval.start), end = moment(this.state.selectedInterval.end);
        return Promise.all([
            UController.planning.getForInterval(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")),
            AvailabilitiesAPI.getAvailabilityAbsenceExceptionalByQuery(`interval.end=ge=${start.format(DateTimeLocalFormat)};interval.start=le=${end.add(6, "month").format(DateTimeLocalFormat)};enabled==true`),
        ])
            .then(([confirmedCycles, globalAbsences]: [Cycle[], AbsenceExceptional[]]) => {
                /**
                 * All confirmed cycles in draft
                 * @type {Dict<Cycle>}
                 */
                let _map = {};
                Object.values(this.state.cycleMap).forEach((cycle: Cycle) => {
                    if (cycle.registerStatus !== CYCLE_STATUS.CONFIRMED)
                        _map[cycle.id] = cycle;
                });
                /**
                 *
                 * @type {Dict<Cycle>}
                 */
                let backupMap = {};
                confirmedCycles.forEach(cycle => {
                    // Only receive those that are not in draft
                    if (!_map[cycle.id]) _map[cycle.id] = cycle;
                    // Save into backup
                    backupMap[cycle.id] = cycle;
                }
                );
                const publicHolidays = globalAbsences.map((absence) => ({
                    start: new Date(absence.interval.start).getTime(),
                    end: new Date(absence.interval.end).getTime()
                }));
                return this.setState({ cycleMap: _map, _cycleMap: backupMap, publicHolidays });
            })
            .catch(error => {
                logErrorGroup(error, "planning.fetch_cycles_and_global_absences");
                this.announceUnexpectedFailure("Echec de chargement des cycles de l'interval " + start.format("DD/MM/YYYY") + " -> " + end.format("DD/MM/YYYY"));
            });
    };

    /**
     *
     * @type {function(Dict<Cycle>): Dict<CompleteRdv>}
     */
    memo_getCompleteRdvMapFromCycleMap = memoize(
        (cycleMap: Dict<Cycle>): Dict<CompleteRdv> => {
            let _map = {};
            Object.values(cycleMap).flatMap(this.memo_parseCycleToCompleteRdvMap).forEach(crdv => _map[crdv.id] = crdv);
            return _map;
        }
    );

    memo_parseCycleToCompleteRdvMap = memoize(
        (cycle: Cycle): CompleteRdv[] => this.parseCycleToCompleteRdvMap(cycle)
    );

    parseCycleToCompleteRdvMap = (cycle: Cycle): Dict<CompleteRdv> =>
        cycle.rendezvousList.map(rdv => new CompleteRdv(cycle, rdv));

    onApplyPlanningModifications = () =>
        this.state.lock ?
            this.setState({
                msg: { content: "Veuillez patienter. Certaines opérations sont en cours." },
            }) :
            this.setState({
                lock: true,
                msg: { content: "En cours d'enregistrement..." }
            }, this.applyPlanningModifications);

    applyPlanningModifications = () =>
        Promise.all([
            // Create cycles
            UController.planning.post(
                Object.values(this.state.cycleMap)
                    .filter(cycle => cycle.registerStatus === CYCLE_STATUS.TO_CREATE)
            ),
            UController.planning.patch(
                Object.values(this.state.cycleMap)
                    .filter(cycle => cycle.registerStatus === CYCLE_STATUS.TO_MODIFY)
            ),
            UController.planning.delete(
                Object.values(this.state.cycleMap)
                    .filter(cycle => cycle.registerStatus === CYCLE_STATUS.TO_DELETE)
            )
        ])
            .then(() => {
                this.setState({
                    lock: true,
                    msg: { content: "En cours de rafraîchissement..." },
                }, () =>
                    this.flushCycles(() =>
                        this.fetchCyclesAndGlobalAbsences()
                            .finally(() => this.setState({
                                lock: false,
                                msg: null
                            })))
                )
            })
            .catch(error => {
                logErrorGroup(error, "planning.apply_planning_draft");
                this.announceUnexpectedFailure();
            })
        ;

    flushCycles = (cb: Function<void>) => this.setState({
        cycleMap: {},
        _cycleMap: {},
        selectedCycleIds: [],
        selectedRdvIds: [],
    }, cb);

    onCancelPlanningModifications = () =>
        this.setState({
            cycleMap: this.state._cycleMap,
            selectedRdvIds: [],
            selectedCycleIds: [],
        });

    /**
     *
     * @param {number} cycleId
     */
    onCycleDeselect = cycleId => {
        if (this.state.lock || !this.state.cycleMap[cycleId]) return;
        let nestedRdvIds = this.state.cycleMap[cycleId].rendezvousList.map(rdv => rdv.id);
        if (this.state.selectedCycleIds.includes(cycleId)) // deselect
            this.setState({
                selectedCycleIds: this.state.selectedCycleIds.filter(id => id !== cycleId),
                selectedRdvIds: this.state.selectedRdvIds.filter(id => !nestedRdvIds.includes(id)),
            });
        else
            this.setState({
                selectedCycleIds: this.state.selectedCycleIds.concat(cycleId),
                selectedRdvIds: this.state.selectedRdvIds.filter(id => !nestedRdvIds.includes(id)).concat(nestedRdvIds),
            })
    };

    setTimelineRange = ({ from, to }: { from: Moment, to: Moment }) => {
        if (!from || !to) return;
        const startDate = from.toISOString(true).substring(0, 10),
            endDate = to.toISOString(true).substring(0, 10);
        this.setState({
            selectedInterval: {
                start: parseISODateToMilliseconds(startDate),
                end: parseISODateToMilliseconds(endDate, true)
            }
        }, () => this.setState({ lock: true },
            () => {
                this.fetchCyclesAndGlobalAbsences()
                    .finally(() => this.setState({ lock: false }))
            }
        ));
    }

    /**
     *
     * @param {number[]} cycleIds
     */
    onCycleDelete = cycleIds => {
        /**
         *
         * @type {Dict<Cycle>}
         */
        let map = { ...this.state.cycleMap };
        let restingSelectedCycleIds = [...this.state.selectedCycleIds];
        let restingSelectedRdvIds = [...this.state.selectedRdvIds];
        cycleIds.forEach(id => {
            let cycle = this.state.cycleMap[id];
            switch (cycle.registerStatus) {
                case CYCLE_STATUS.TO_CREATE:
                    delete map[id];
                    restingSelectedCycleIds = restingSelectedCycleIds.filter(i => i !== id);
                    let nestedRdvIds = cycle.rendezvousList.map(rdv => rdv.id);
                    restingSelectedRdvIds = restingSelectedRdvIds.filter(i => !nestedRdvIds.includes(i));
                    return;
                case CYCLE_STATUS.CONFIRMED:
                case CYCLE_STATUS.TO_MODIFY:
                    // Get the original to mutate
                    let originalCycle = this.state._cycleMap[cycle.id];
                    map[id] = {
                        ...originalCycle,
                        registerStatus: CYCLE_STATUS.TO_DELETE,
                        rendezvousList: originalCycle.rendezvousList.map(rdv => ({
                            ...rdv,
                            registerStatus: CYCLE_STATUS.TO_DELETE,
                        }))
                    };
                    return;
                case CYCLE_STATUS.TO_DELETE:
                default:
                    return;
            }
        });
        this.setState({
            cycleMap: map,
            selectedCycleIds: restingSelectedCycleIds,
            selectedRdvIds: restingSelectedRdvIds,
        });
    };

    /**
     * Register changes. Change validation should be done in timeline wrapper
     * @param {CompleteRdv[]} crdvs affected rdvs with applied changes
     */
    onRdvsChange = (crdvs: CompleteRdv[]) => {
        /**
         *
         * @type {Dict<Cycle>}
         */
        let cycleChanges = {};
        crdvs.forEach(crdv => {
            if (!this.state.cycleMap[crdv.cycleId] // Cycle not exists
                || !this.state.cycleMap[crdv.cycleId].rendezvousList.find(rdv => rdv.id === crdv.id)) // Rdv not exists
                return;
            if (!cycleChanges[crdv.cycleId])
                cycleChanges[crdv.cycleId] = {
                    ...this.state.cycleMap[crdv.cycleId],
                    protocol: crdv.protocol,
                    registerStatus: this.state.cycleMap[crdv.cycleId].registerStatus !== CYCLE_STATUS.TO_CREATE ?
                        CYCLE_STATUS.TO_MODIFY : CYCLE_STATUS.TO_CREATE
                };
            cycleChanges[crdv.cycleId].rendezvousList = cycleChanges[crdv.cycleId].rendezvousList.map(rdv =>
                rdv.id !== crdv.id ? rdv :
                    {
                        ...crdv.rdv,
                        registerStatus: rdv.registerStatus !== CYCLE_STATUS.TO_CREATE ? CYCLE_STATUS.TO_MODIFY : CYCLE_STATUS.TO_CREATE,
                    }
            );
        });
        this.setState({
            cycleMap: {
                ...this.state.cycleMap,
                ...cycleChanges,
            }
        });
    };

    memo_getCyclesToCreate = memoize(
        (cycleMap: Dict<Cycle>): Cycle[] =>
            Object.values(cycleMap).filter(c => c.registerStatus === CYCLE_STATUS.TO_CREATE)
    );

    onCompleteRdvSelect = (selectedRdv: CompleteRdv) => {
        if (this.state.lock) return;
        if (this.state.selectedCycleIds.includes(selectedRdv.rdv.id)) return;
        const _futureRdvsSameCycle = this.getFutureRdvIdsOfSameCycle(selectedRdv);
        const _selectedRdvIds = this.state.selectedRdvIds.filter(id => !_futureRdvsSameCycle.includes(id)).concat(_futureRdvsSameCycle);
        this.setState({
            selectedRdvIds: _selectedRdvIds,
            selectedCycleIds: this.findIdsOfCycleHavingAtLeastOneRdvIn(_selectedRdvIds),
        });
    };

    onRdvSelect = (rdv: Rendezvous) => {
        if (this.state.lock) return;
        if (this.state.selectedRdvIds.includes(rdv.id)) return;
        const selectedRdvIds = this.state.selectedRdvIds.concat(rdv.id);
        this.setState({
            selectedRdvIds,
            selectedCycleIds: this.findIdsOfCycleHavingAtLeastOneRdvIn(selectedRdvIds),
        });
    }

    getFutureRdvIdsOfSameCycle = (rdv: CompleteRdv): number[] => this.state.cycleMap[rdv.cycleId].rendezvousList.filter(r => r.sessionDay >= rdv.rdv.sessionDay).map(r => r.id);

    onCompleteRdvDeselect = (unselectedRdv: CompleteRdv | undefined) => {
        if (this.state.lock) return;
        const unselectedRdvIds = unselectedRdv ? this.getFutureRdvIdsOfSameCycle(unselectedRdv) : null;
        const _selectedRdvIds = unselectedRdvIds ? this.state.selectedRdvIds.filter(id => !unselectedRdvIds.includes(id)) : [];
        this.setState({
            selectedRdvIds: _selectedRdvIds,
            selectedCycleIds: this.findIdsOfCycleHavingAtLeastOneRdvIn(_selectedRdvIds),
        });
    };

    onRdvsDeselectAllSelected = () => this.setState({
        selectedRdvIds: [],
        selectedCycleIds: []
    });

    /**
     * Only keep cycles that have at least one selected rdv
     * @param restingRdvIds
     * @return {number[]}
     */
    findIdsOfCycleHavingAtLeastOneRdvIn = (restingRdvIds: number[]) =>
        Object.keys(this.state.cycleMap).filter(cycleId =>
            this.state.cycleMap[cycleId].rendezvousList.some(rdv => restingRdvIds.includes(rdv.id)))
            .map(a => parseInt(a));

    onRdvsDelete = (rdvIdsToDelete: number[]) => {
        if (this.state.lock || !rdvIdsToDelete || rdvIdsToDelete.length === 0) return;
        let _map = { ...this.state.cycleMap };
        Object.values(this.state.cycleMap).forEach((cycle: Cycle) => {
            if (!cycle.rendezvousList.some(rdv => rdvIdsToDelete.includes(rdv.id))) return;
            switch (cycle.registerStatus) {
                case CYCLE_STATUS.TO_CREATE:
                    let _cycle = {
                        ...cycle,
                        rendezvousList: cycle.rendezvousList.filter(rdv => !rdvIdsToDelete.includes(rdv.id))
                    };
                    if (_cycle.rendezvousList.length === 0)
                        delete _map[cycle.id];
                    else
                        _map[cycle.id] = _cycle;
                    break;
                case CYCLE_STATUS.TO_MODIFY:
                case CYCLE_STATUS.CONFIRMED:
                    // Find id of the first RDV to delete, then mark all RDV after that to be deleted
                    let startingRdv = cycle.rendezvousList.find(rdv => rdvIdsToDelete.includes(rdv.id));
                    if (startingRdv !== undefined) {
                        let startingRdvIdx = cycle.rendezvousList.indexOf(startingRdv);
                        _map[cycle.id] = {
                            ...cycle,
                            registerStatus: CYCLE_STATUS.TO_DELETE,
                            rendezvousList: cycle.rendezvousList
                                .map((rdv, idx) =>
                                    idx >= startingRdvIdx ?
                                        {
                                            ...rdv,
                                            registerStatus: CYCLE_STATUS.TO_DELETE
                                        }
                                        : rdv
                                ),
                        };
                    }
                    break;
                case CYCLE_STATUS.TO_DELETE:
                default:
                    break;
            }
        });
        this.setState({
            cycleMap: _map,
        });
    };

    onRdvDelete = (rdv: Rendezvous) => this.onRdvsDelete([rdv.id]);

    onRdvsDeleteAllSelected = () => this.onRdvsDelete(this.state.selectedRdvIds);

    onSendCycleRequests = (cyclePlanningRequest: CyclePlanningRequest,
        startDate: string,
        endDate: string | null,
        cbOnSuccess: Function) =>
        this.state.lock ?
            this.setState({
                msg: { content: "En cours de calcul. Veuillez patienter." }
            }) :
            this.setState({
                lock: true,
                msg: { content: "En cours de calcul..." },
            }, () => this.sendCycleRequests(cyclePlanningRequest, startDate, endDate, cbOnSuccess));

    sendCycleRequests = (cyclePlanningRequests: CyclePlanningRequest[],
        startDate: string,
        endDate: string | null,
        cbOnSuccess: Function) =>
        UController.planning.postRequestsWithExplanation(
            cyclePlanningRequests,
            // Rdvs to create or to modify
            Object.values(this.state.cycleMap)
                .filter((cycle: Cycle): boolean => cycle.registerStatus === CYCLE_STATUS.TO_CREATE || cycle.registerStatus === CYCLE_STATUS.TO_MODIFY)
                .map(cycle => CycleWrapper.from(cycle)),
            // Rdvs to delete. Only keep the id
            Object.values(this.state.cycleMap)
                .flatMap((cycle: Cycle): Rendezvous[] => cycle.rendezvousList)
                .filter((rdv: Rendezvous): boolean => rdv.registerStatus === CYCLE_STATUS.TO_DELETE)
                .map(rdv => rdv.id),
            // Start date in iso format
            startDate,
            // End date in iso format, or not
            endDate)
            .then(({ cycles, explanation, solverErrorMessage, solverStatus }) => {
                if (solverStatus === "SUCCESS" && !!cycles && cycles.length > 0) {
                    cycles.forEach(cycle => {
                        cycle.id = -this._counter++;
                        cycle.rendezvousList.forEach(rdv => rdv.id = -this._counter++);
                    });
                    let sessionDates = cycles.flatMap(cycle => cycle.rendezvousList).map(r => r.sessionDay).sort();
                    const explanationHTML = this.buildPlanningExplanation(explanation, cycles, { startDate, endDate });
                    const adjustedSelectedInterval = sessionDates.length === 0 ? undefined : {
                        start: moment(sessionDates[0]),
                        end: moment(sessionDates[0]).add(SELECTED_INTERVAL_DEFAULT_DURATION)
                    };
                    this.setState({
                        lock: false,
                        msg: explanationHTML ? {
                            title: "Réussi",
                            level: "success",
                            content: this.combineMessageAndPlanningExplanation("Nouvelle proposition de planification.", explanationHTML)
                        } : null,
                        isPopupAddPatientContainerIsOpen: false,
                        cycleMap: addAllToMap(cycles.filter(c => !this.state.cycleMap[c.id]), this.state.cycleMap),
                        selectedInterval: !adjustedSelectedInterval ? undefined : {
                            start: adjustedSelectedInterval.start.valueOf(),
                            end: adjustedSelectedInterval.end.valueOf()
                        }
                    }, () => {
                        adjustedSelectedInterval && this.pickerRef?.current?.setRange(adjustedSelectedInterval.start, adjustedSelectedInterval.end);
                        typeof cbOnSuccess === "function" && cbOnSuccess();
                    });
                } else {
                    logErrorGroup(new Error(solverErrorMessage), "planning.algorithm.executions.fail");
                    const explanationHTML = this.buildPlanningExplanation(explanation, cycles, { startDate, endDate });
                    this.setState({
                        lock: false,
                        msg: {
                            title: "Echec",
                            level: "danger",
                            content: this.combineMessageAndPlanningExplanation("Planification impossible.", explanationHTML)
                        },
                        isPopupAddPatientContainerIsOpen: false,
                    });
                };
            })
            .catch(e => {
                logErrorGroup(e, "planning.algorithms.executions.send_requests");
                this.announceUnexpectedFailure();
            });

    buildPlanningExplanation = (explanation?: PlanningExplanation, cycles?: Cycle[], horizon: { startDate: DateString, endDate?: DateString }): ReactElement | undefined => {
        if (!explanation) return;
        const explanations: ReactElement[] = [];
        const firstDayUnassignment = explanation.firstDayUnassignment;
        if (!firstDayUnassignment) return;
        if (firstDayUnassignment.globalAbsence)
            explanations.push(<span>Le service est fermé le {printIsoDate(horizon.startDate)}</span>);
        const unavailableReferentDoctors = firstDayUnassignment.unavailableReferentDoctors;
        if (unavailableReferentDoctors) {
            Object.values(unavailableReferentDoctors)
                .forEach(detection => {
                    const doctorFullname = getFullNameWithPrefixDoctor(detection.staff);
                    switch (detection.reason) {
                        case "NO_AVAILABILITY_TIMELINE":
                            explanations.push(<span>{doctorFullname} n'a pas déclaré ses disponibilités</span>);
                            break;
                        case "NO_AVAILABILITY":
                            explanations.push(<span>{doctorFullname} n'est pas disponible le jour sélectionné {printIsoDate(detection.day)}</span>);
                            break;
                        case "BUSY":
                            explanations.push(<span>{doctorFullname} est trop occupé le jour sélectionné {printIsoDate(detection.day)}</span>);
                            break;
                        default:
                            break;
                    }
                })
        }
        const patientExistingAssignments = firstDayUnassignment.patientExistingAssignments;
        if (patientExistingAssignments) {
            Object.values(patientExistingAssignments)
                .forEach(detection => {
                    explanations.push(<span>{getFullNameWithPrefix(detection.patient)} a déjà un RDV planifié le jour sélectionné {printIsoDate(detection.day)}</span>)
                });
        }
        const insufficientSeats = firstDayUnassignment.insufficientSeats;
        if (insufficientSeats) {
            Object.values(insufficientSeats)
                .forEach(detection => {
                    if (!detection.seatTypeRequirement) {
                        explanations.push(<span>Nombre d'hébergements insuffisant pour {getFullNameWithPrefix(detection.patient)} le jour sélectionné {printIsoDate(detection.day)}</span>)
                    } else {
                        explanations.push(<span>Nombre de {(this.SUPPORTED_SEAT_TYPES[detection.seatTypeRequirement] ? this.SUPPORTED_SEAT_TYPES[detection.seatTypeRequirement].translation : detection.seatTypeRequirement).toLowerCase()}s insuffisant pour {getFullNameWithPrefix(detection.patient)} le jour sélectionné {printIsoDate(detection.day)}</span>)
                    }
                })
        }

        // Should not return anything if no explanation found
        if (explanations.length === 0) return;
        return (
            <ul>
                {explanations.map((exp, idx) => <li key={idx}>{exp}</li>)}
            </ul>
        )
    }

    combineMessageAndPlanningExplanation = (msg: string, explanation?: ReactElement): string | ReactElement => {
        if (!explanation) return msg;
        return (
            <>
                <p>{msg}</p>
                {explanation}
            </>
        )
    }

    announceUnexpectedFailure = (msg?: string) => this.setState({
        lock: false,
        msg: { title: "Erreur", content: msg ?? "Une erreur imprévue est survenue. Veuillez ressayer ultérieuement." }
    });

    updateSelectedSectorIds = (selectedSectorIds: Number[]) => this.setState({ selectedSectorIds });

    updateSelectedSectorIdsForRdvs = (selectedSectorIdsForRdvs: Number[]) => this.setState({ selectedSectorIdsForRdvs })

    toggleSelectedSeatTypes = (type: SeatType) => this.setState({
        selectedSeatTypes: !this.state.selectedSeatTypes.includes(type) ? this.state.selectedSeatTypes.concat(type)
            : this.state.selectedSeatTypes.filter(t => t !== type)
    });

    toggleSelectedSeatWings = (wing: SeatGroup) => this.setState({
        selectedSeatWings: this.state.selectedSeatWings.includes(wing) ? this.state.selectedSeatWings.filter(w => w !== wing)
            : this.state.selectedSeatWings.concat(wing)
    });

    getPlaceLabel = (placeId: ModelId): React.Component | undefined => {
        if (!this.state.placeMap[placeId]) return;
        const place = this.state.placeMap[placeId];
        return <b><span style={{ color: place.backgroundColor }}>{place.title} </span></b>
    }

    memo_getFilteredPlaceList = memoize(
        (placeList: Place[], selectedSectorIds: ModelId[], selectedSeatTypes: SeatType[], selectedSeatWings: SeatGroup[]): Place[] =>
            placeList.filter((place: Place) => selectedSectorIds.includes(place.rawData.sectorId)
                && selectedSeatTypes.includes(place.rawData.type.seatType)
                && (selectedSeatWings.length === 0 || selectedSeatWings.some(wing => wing.seats?.some(s => s.id === place.rawData.id)))
            )
    );

    exportPlanningAppoints = () => {
        let completeRdvMap = this.memo_getCompleteRdvMapFromCycleMap(this.state.cycleMap);
        let _start = moment(this.state.selectedInterval.start).startOf("day"),
            _end = moment(this.state.selectedInterval.end).endOf("day")

        generatePlanningRdvList({
            startDate: _start.format(moment.HTML5_FMT.DATE),
            endDate: _end.format(moment.HTML5_FMT.DATE)
        },
            Object.values(completeRdvMap)
                .filter((rdv: CompleteRdv) => moment(rdv.rdv.sessionDay).isBetween(_start, _end, "day", "[]"))
                .sort((r1: CompleteRdv, r2: CompleteRdv) => r1.rdv.sessionDay < r2.rdv.sessionDay ? -1
                    : r1.rdv.sessionDay > r2.rdv.sessionDay ? 1
                        : r1.rdv.session.start < r2.rdv.session.start ? -1
                            : r1.rdv.session.start > r2.rdv.session.start ? 1
                                : r1.rdv.session.end < r2.rdv.session.end ? -1
                                    : r1.rdv.session.end > r2.rdv.session.end ? 1
                                        : 0)
        );
    }

    memo_sectors = memoize((sectorDict) => Object.values(sectorDict));

    closeRdvEditModal = () => this.setState({ editingRdvId: null });

    onRdvEdit = (editingRdvId: Number) => this.setState({ editingRdvId });

    onRdvEditSubmit = (modifiedRdv: CompleteRdv, newStartTime?: number) => {
        if (!this.state.cycleMap[modifiedRdv.cycleId]) return;
        const modifyingCycle = modifiedRdv.protocol.id !== this.state.cycleMap[modifiedRdv.cycleId].protocol.id;
        const cycle = modifyingCycle ? {
            ...this.state.cycleMap[modifiedRdv.cycleId],
            protocol: modifiedRdv.protocol.id
        } : this.state.cycleMap[modifiedRdv.cycleId];
        var modifiedRdvs: CompleteRdv[] = null;
        if (newStartTime) {
            // Apply check on changing date time
            // Apply for all future rdv if this option is activated
            const rdvsToModify = [modifiedRdv.rdv];
            if (this.state.planningActionConfig.moveFutureRdvSameCycle)
                rdvsToModify.push(...cycle.rendezvousList.filter(r => r.sessionDay > modifiedRdv.rdv.sessionDay));
            try {
                modifiedRdvs = checkAndShiftDateTimeRdvs(
                    modifiedRdv.rdv.id,
                    rdvsToModify.map(r => r.id),
                    newStartTime,
                    this.state.config,
                    this.state.planningActionConfig,
                    this.state.publicHolidays,
                    addAllToMap(rdvsToModify.map(r => ({
                        id: r.id,
                        start_time: moment(r.sessionDay + "T" + r.session.start).valueOf(),
                        end_time: moment(r.sessionDay + "T" + r.session.end).valueOf(),
                    }))),
                    addAllToMap(rdvsToModify.map(r => new CompleteRdv(cycle, r)))
                );
            } catch (e) {
                if (e instanceof RdvMoveError) {
                    this.announceWarning(e.message);
                } else {
                    this.announceWarning("Une erreur est survenue. Veuillez réessayer ultérieurement");
                    logErrorGroup(e, "planning.move_rdvs");
                }
                return;
            }
        } else modifiedRdvs = [modifiedRdv];
        this.onRdvsChange(modifiedRdvs);
        this.setState({ editingRdvId: null });
    };

    addOrSubtractPeriodToTimelineRange = (type: "add" | "subtract") => {
        let _diff = moment(this.state.selectedInterval.end).diff(moment(this.state.selectedInterval.start), "days") + 1,
            _newInterval = {
                start: type === "add" ? moment(this.state.selectedInterval.start).add(_diff, "day") : moment(this.state.selectedInterval.start).subtract(_diff, "day"),
                end: type === "add" ? moment(this.state.selectedInterval.end).add(_diff, "day") : moment(this.state.selectedInterval.end).subtract(_diff, "day"),
            }

        this.setState({
            selectedInterval: {
                start: parseInt(_newInterval.start.format("x")),
                end: parseInt(_newInterval.end.format("x")),
            }
        })
        this.pickerRef?.current?.setRange(_newInterval.start, _newInterval.end)
    }

    onUpdatePlanningActionConfig = (nextConfig: PlanningActionConfig) => {
        this.setState({
            planningActionConfig: nextConfig
        });
    }

    announceWarning = (msg?: String, lock?: Boolean = false): void => {
        if (!msg) return;
        this.setState({
            msg: { content: msg, title: "Alerte", level: "warning" },
            lock,
            msgTimestamp: new Date().valueOf() // Force rerender alert
        });
    }

    onSelectPatientCycle = (c: Cycle) => {
        const cycleId = c.id;
        if (this.state.lock || !this.state.cycleMap[cycleId]) return;
        let nestedRdvIds = this.state.cycleMap[cycleId].rendezvousList.map(rdv => rdv.id);
        if (this.state.selectedCycleIds.includes(cycleId)) return; // Already selected
        else
            this.setState({
                selectedCycleIds: this.state.selectedCycleIds.concat(cycleId),
                selectedRdvIds: this.state.selectedRdvIds.concat(nestedRdvIds),
            })
    };

    onChangeTargetDoctorIds = (targetDoctorIds: number[]) => {
        if (this.state.lock) return;
        this.setState({
            selectedDoctorIds: targetDoctorIds
        });
    }

    render() {
        const completeRdvMap = this.memo_getCompleteRdvMapFromCycleMap(this.state.cycleMap);
        // const cyclesToCreate = this.memo_getCyclesToCreate(this.state.cycleMap);
        const sectors = this.memo_sectors(this.state.dict.sector);
        const filteredPlaceList = this.memo_getFilteredPlaceList(this.state.placeList, this.state.selectedSectorIds, this.state.selectedSeatTypes, this.state.selectedSeatWings);
        return (
            <>
                <Container fullHeight>
                    <TopContainer>
                        <div className="d-flex align-items-center">
                            <Button onClick={() => this.setState({ isLeftSidebarOpen: !this.state.isLeftSidebarOpen })} className="print-btn active" style={{ color: "#435f71" }}>
                                <span className={`fas fa-${this.state.isLeftSidebarOpen ? 'outdent' : 'indent'} fa-lg`} />
                            </Button>
                            <Button onClick={this.exportPlanningAppoints} className="print-btn">
                                <span className="fas fa-print fa-lg" />
                            </Button>
                            <Button onClick={() => this.setState({ isPopupAddPatientContainerIsOpen: !this.state.isPopupAddPatientContainerIsOpen, selectedCycleIds: [], selectedRdvIds: [] })} className="print-btn active" style={{ color: "#435f71" }}>
                                <span className={`fas fa-plus fa-lg`} /> Planifier
                            </Button>
                            {!!this.state.selectedInterval.start &&
                                <div className="px-2 d-flex align-items-center print-btn">
                                    <span
                                        onClick={() => this.addOrSubtractPeriodToTimelineRange("subtract")}
                                        className='fas fa-chevron-left'
                                        style={{ cursor: 'pointer' }} />
                                    <DateRangeSelector
                                        ref={this.pickerRef}
                                        popupPosition='left'
                                        reloadAuto
                                        definedPeriodsProposals='long'
                                        horizon={{
                                            from: moment(this.state.selectedInterval.start),
                                            to: moment(this.state.selectedInterval.end)
                                        }}
                                        handleSubmit={this.setTimelineRange} />
                                    <span
                                        onClick={() => this.addOrSubtractPeriodToTimelineRange("add")}
                                        className='fas fa-chevron-right'
                                        style={{ cursor: 'pointer' }} />
                                </div>
                            }
                            {
                                !!this.state.config &&
                                <PlanningActionPannel
                                    planningConfig={this.state.config}
                                    planningActionConfig={this.state.planningActionConfig}
                                    onUpdatePlanningActionConfig={this.onUpdatePlanningActionConfig}
                                />
                            }
                            <span className="px-2">Couleur RDV: </span>
                            <ButtonGroup>
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => this.setState({ rdvDisplayMode: "BY_SECTOR" })}
                                    active={this.state.rdvDisplayMode === "BY_SECTOR"}
                                >
                                    Spécialité
                                </Button>
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => this.setState({ rdvDisplayMode: "BY_DOCTOR" })}
                                    active={this.state.rdvDisplayMode === "BY_DOCTOR"}
                                >
                                    Médecin
                                </Button>
                            </ButtonGroup>
                        </div>
                    </TopContainer>
                    <BottomContainer>
                        {this.state.isLeftSidebarOpen &&
                            <div className="d-flex flex-column align-items-start"
                                style={{
                                    boxSizing: "border-box",
                                    borderRight: "1px solid #C6C6C6",
                                    width: "16%",
                                }}>
                                <FilterGroup label={"Filtres d'hébergements"} active={!!env.DISPLAY_OPTIONS?.PLANNING?.FILTER?.BY_SEAT}>
                                    <div className="p-1">
                                        <SectorFilter sectors={sectors} onChange={this.updateSelectedSectorIds} />
                                    </div>
                                    <div className="p-1">
                                        <SeatTypeToggler values={this.SUPPORTED_SEAT_TYPES}
                                            selectedValues={this.state.selectedSeatTypes}
                                            onToggleValue={this.toggleSelectedSeatTypes} />
                                    </div>
                                    <div className="p-1">
                                        <SeatGroupToggler values={this.state.seatWings}
                                            selectedValues={this.state.selectedSeatWings}
                                            onToggleValue={this.toggleSelectedSeatWings} />
                                    </div>
                                </FilterGroup>
                                <FilterGroup label={"Filtres de patients"} active={!!env.DISPLAY_OPTIONS?.PLANNING?.FILTER?.BY_PATIENT}>
                                    <div className="p-1 w-100">
                                        <PatientFinder cycleMap={this.state.cycleMap}
                                            onSelectPatientCycle={this.onSelectPatientCycle} />
                                    </div>
                                    <div className="p-1 w-100">
                                        <SectorFilter sectors={sectors} onChange={this.updateSelectedSectorIdsForRdvs} />
                                    </div>
                                    <div className="p-1 w-100">
                                        <DoctorFilter onChangeTargetDoctorIds={this.onChangeTargetDoctorIds} />
                                    </div>
                                </FilterGroup>
                            </div>
                        }
                        <MainContainer>
                            {
                                this.state.config instanceof OptaConfig &&
                                this.state.placeList.length > 0 &&
                                this.state.selectedInterval.start && this.state.selectedInterval.end &&
                                <TimelineWrapper
                                    config={this.state.config}
                                    planningActionConfig={this.state.planningActionConfig}
                                    completeRdvMap={completeRdvMap}
                                    publicHolidays={this.state.publicHolidays}
                                    placeList={filteredPlaceList}
                                    selectedInterval={this.state.selectedInterval}
                                    onRdvsChange={this.onRdvsChange}
                                    dictionary={this.state.dict}
                                    selectedRdvIds={this.state.selectedRdvIds}
                                    selectedSectorIdsForRdvs={this.state.selectedSectorIdsForRdvs}
                                    selectedDoctorIds={this.state.selectedDoctorIds}
                                    onRdvsSelect={this.onCompleteRdvSelect}
                                    onRdvsDeselect={this.onCompleteRdvDeselect}
                                    onRdvsDelete={this.onRdvsDelete}
                                    onRdvEdit={this.onRdvEdit}
                                    style={{
                                        minLabelVisibleWidth: 50
                                    }}
                                    announceWarning={this.announceWarning}
                                    currentDate={this.state.currentDate}
                                    rdvDisplayMode={this.state.rdvDisplayMode}
                                />
                            }
                        </MainContainer>
                    </BottomContainer>
                    <SubBottomContainer>
                        <div className="d-flex flex-column">
                            <CycleSelectorViewer
                                label={"Cycles séléctionés"}
                                cycles={this.state.selectedCycleIds.map(cycleId => this.state.cycleMap[cycleId]).filter(c => !!c)} // Sometimes the selected cycles are not in the selected interval
                                onRdvsDeleteAll={this.onRdvsDeleteAllSelected}
                                onRdvsDeselectAll={this.onRdvsDeselectAllSelected}
                                onCycleDeselect={this.onCycleDeselect}
                                onCycleDelete={this.onCycleDelete}
                                onRdvDelete={this.onRdvDelete}
                                onRdvEdit={this.onRdvEdit}
                                getPlaceLabel={this.getPlaceLabel}
                                currentDate={this.state.currentDate}
                            />
                        </div>
                        <div style={{ fontSize: "1rem" }}>
                            <span className="mx-2">
                                <span className="working-hour-marker-start px-3 d-block-inline" style={{ border: "1px solid black" }} />
                                <span className="d-block-inline ml-1">Accueil des patients ({printIsoTime(this.state.config.realOpeningHour)})</span>
                            </span>
                            <span className="mx-2">
                                <span className="working-hour-marker-end px-3 d-block-inline" style={{ border: "1px solid black" }} />
                                <span className="d-block-inline ml-1">Fermeture de service ({printIsoTime(this.state.config.closingHour)})</span>
                            </span>
                            <span className="mx-2">
                                <span className="no-service px-3 d-block-inline" style={{ border: "1px solid black" }} />
                                <span className="d-block-inline ml-1">Pas de service</span>
                            </span>
                            <span className="mx-2">
                                <span className="public-holiday px-3 d-block-inline" style={{ border: "1px solid black" }} />
                                <span className="d-block-inline ml-1">Férié</span>
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                            <Button className="bg-danger text-white"
                                onClick={this.onCancelPlanningModifications}>
                                Annuler
                            </Button>
                            <Button className="bg-success text-white"
                                onClick={this.onApplyPlanningModifications}>
                                Enregistrer
                            </Button>

                        </div>
                    </SubBottomContainer>
                    <PopupContainer view={this.state.isPopupAddPatientContainerIsOpen}>
                        <CycleDemandListCreator
                            close={() => this.setState({ isPopupAddPatientContainerIsOpen: false })}
                            lock={this.state.lock}
                            sendCycleRequests={this.onSendCycleRequests}
                            sectorDict={this.state.dict.sector}
                            config={this.state.config}
                            seatWings={this.state.seatWings} />
                    </PopupContainer>
                </Container>
                <InfoModal msg={this.state.msg?.content}
                    title={this.state.msg?.title ?? "Message d'information"}
                    level={this.state.msg?.level}
                    key={this.state.msgTimestamp}
                />
                {
                    !!this.state.editingRdvId && !!completeRdvMap[this.state.editingRdvId] &&
                    <RendezvousForm crdv={completeRdvMap[this.state.editingRdvId]}
                        onSubmit={this.onRdvEditSubmit}
                        onClose={this.closeRdvEditModal}
                        currentDate={this.state.currentDate}
                        config={this.state.config}
                        sectorDict={this.state.dict.sector}
                    />
                }
                <Prompt
                    when={Object.values(this.state.cycleMap)
                        .some(cycle => cycle.registerStatus === CYCLE_STATUS.TO_CREATE
                            || cycle.registerStatus === CYCLE_STATUS.TO_MODIFY
                            || cycle.registerStatus === CYCLE_STATUS.TO_DELETE)
                    }
                    message='Vous avez des changements non enregistrés ou des cycles créés non validés. Voulez-vous quitter la page ?'
                />
            </>
        );
    }
}

export default withToastManager(Planning);

type PlanningActionPannelProps = {
    planningConfig: OptaConfig,
    planningActionConfig: PlanningActionConfig,
    onUpdatePlanningActionConfig: (PlanningActionConfig => void),
}
type PlanningActionDescriptor = {
    attributeName: String,
    icon: React.Component,
    tooltip: String,
}
const planningActionDescriptors: PlanningActionDescriptor[] = [
    {
        attributeName: "moveFutureRdvSameCycle",
        icon: <i className="fas fa-list" />,
        tooltip: "Déplacement automatiquement les RDV futurs des cycles sélectionnés",
    },
    {
        attributeName: "limitRdvInOpeningHour",
        icon: <i className="fas fa-business-time" />,
        tooltip: "Interdire de déplacer les RDV en dehors de l'heure d'accueil des patients",
    },
    {
        attributeName: "noRdvOnWeekendsAndHolidays",
        icon: <i className="fas fa-sun" />,
        tooltip: "Interdire de déplacer les RDV aux weekends et fériés",
    }
]
const PlanningActionPannel = (props: PlanningActionPannelProps) => {
    const toggleActionAttribute = (attributeName: String) => props.onUpdatePlanningActionConfig({
        ...props.planningActionConfig,
        [attributeName]: !props.planningActionConfig[attributeName]
    });
    return (
        <div id="planning_action_config" className="px-2 d-flex align-items-center print-btn">
            {
                planningActionDescriptors.map(a => <PlanningActionToggleButton
                    attributeName={a.attributeName}
                    attributeValue={props.planningActionConfig[a.attributeName]}
                    icon={a.icon}
                    tooltip={a.tooltip}
                    onToggle={() => toggleActionAttribute(a.attributeName)}
                    key={a.attributeName}
                />)
            }
        </div>
    )
}

const PlanningActionToggleButton = (props: {
    attributeName: String,
    attributeValue: Boolean,
    icon: React.Component,
    tooltip: String,
    onToggle: () => void
}) => (<>
    <span className={`btn_mode ${props.attributeValue ? "active" : ""}`}
        onClick={props.onToggle}
        id={"planning_action_config__" + props.attributeName}
        style={{ cursor: "pointer" }}>
        {props.icon}
    </span>
    <UncontrolledTooltip target={"planning_action_config__" + props.attributeName}>
        {props.tooltip}
    </UncontrolledTooltip>
</>)

const SeatTypeToggler = (props: {
    values: { [key: SeatType]: SeatTypeDescription },
    selectedValues: SeatType[],
    onToggleValue: (v: SeatType) => void
}) => (<div style={{ display: "flex", flexWrap: "wrap" }}><span className="font-weight-bold mr-2">Types d'hébergements :</span>
    {
        Object.values(props.values)
            .map((seatTypeDesc: SeatTypeDescription) => <span className="label"
                style={{
                    background: props.selectedValues.includes(seatTypeDesc.type) ? "#2F80ED" : "rgba(47, 53, 66,.2)",
                    color: props.selectedValues.includes(seatTypeDesc.type) ? "#fff" : "inherit"
                }}
                key={seatTypeDesc.type}
                onClick={() => props.onToggleValue(seatTypeDesc.type)}
            >
                <i className={`fas fa-${seatTypeDesc.iconFa}`} />
            </span>
            )
    }
</div>)

const SeatGroupToggler = (props: {
    values: {
        [key: number]: SeatGroup
    },
    selectedValues: SeatGroup[],
    onToggleValue: (v: SeatGroup) => void
}) => (<div style={{ display: "flex", flexWrap: "wrap" }}><span className="font-weight-bold mr-2">Ailes :</span>
    {
        Object.values(props.values)
            .map(value => <>
                <span className="label"
                    id={`seat-group-${value.id}`}
                    style={{
                        background: !props.selectedValues.includes(value) ? "rgba(47, 53, 66,.05)" : undefined,
                    }}
                    key={value.id}
                    onClick={() => props.onToggleValue(value)}
                >
                    {value.name}
                </span>
                {
                    value.description &&
                    <UncontrolledTooltip target={`seat-group-${value.id}`}>
                        {value.description}
                    </UncontrolledTooltip>
                }
            </>
            )
    }
</div>)

const SuggestionItem = styled('div')`
    span{
        display: block;
        &:nth-of-type(1){
            font-weight: 700;
        }
        &:nth-of-type(2){
            font-style: italic;
        }
    }
`

const PatientFinder = ({
    cycleMap,
    onSelectPatientCycle
}: {
    cycleMap: Dict<Cycle>,
    onSelectPatientCycle: (c: Cycle) => void,
}) => {

    const filterPatientCycles = useCallback((filter: String) => {
        const filterTokens = filter.trim().split(/\s+/).map(f => f.toLowerCase());
        if (filterTokens.length === 0) return Promise.resolve([]);
        const matchByPatientFirstNames = (p: Patient) => filterTokens.some(f => p.firstName?.toLowerCase().includes(f));
        const matchByPatientLastNames = (p: Patient) => filterTokens.some(f => p.lastName?.toLowerCase().includes(f) || p.partnerName?.toLowerCase().includes(f));
        const matchByPatientExternalIdValue = (p: Patient) => filterTokens.some(f => p.externalId?.value?.toLowerCase().includes(f));
        return Promise.resolve(cycleMap)
            .then(cm => Object.values(cm).filter(c => matchByPatientFirstNames(c.patient) || matchByPatientLastNames(c.patient) || matchByPatientExternalIdValue(c.patient)));
    },
        [cycleMap]);

    const getPatientCycleValue = useCallback((suggestion: Cycle) => suggestion.id, []);

    const renderPatientCycleChoice = useCallback((suggestion: Cycle): string => (
        <SuggestionItem>
            <span>{getFullNameWithPrefix(suggestion.patient)}</span>
            {!!suggestion.birthday && <span>({getLocalDateStrFromISO(suggestion.patient.birthday)})</span>}
            {!!suggestion.patient.externalId?.value && <span>IPP: {suggestion.patient.externalId?.value}</span>}
            <span>{suggestion.protocol.name} ({printIsoDate(suggestion.interval.start)} - {printIsoDate(suggestion.interval.end)})</span>
        </SuggestionItem>
    ), []);

    return (
        <EntityPicker
            async
            debounce={1000}
            placeholder={"IPP/nom(s)/prénom(s) de patient"}
            onChooseValue={onSelectPatientCycle}
            clearOnSelect={false}
            getFilteredSuggestions={filterPatientCycles}
            getSuggestionValue={getPatientCycleValue}
            renderSuggestion={renderPatientCycleChoice} />
    )
};

const FilterGroup = (props) => {

    const [open, setOpen] = useState(props.active);

    const toggleOpen = useCallback(() => setOpen(o => !o), [setOpen])
    return (
        <>
            <div className="p-1">
                <span onClick={toggleOpen}>
                    {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </span>
                <span className="font-weight-bold font-italic">{props.label}</span>
            </div>
            {open && props.children}
        </>
    );
}